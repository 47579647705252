@import "Scss/Variable.scss";
@import "~bootstrap-4-grid/scss/grid.scss";
* {
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}
*:hover {
  text-decoration: none;
}
html {
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
body {
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 1.5;
  font-family: $fontFace;
  background-color: $bgc;
  user-select: none;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}
svg {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 0.625rem;
  opacity: 0;
  &-track {
    background: rgba($black, 0);
    border-radius: 0.625rem;
  }
  &-thumb {
    background: rgba($black, 0.2);
    border-radius: 0.625rem;
  }
  &-corner {
    background: transparent;
  }
}
*:hover {
  text-decoration: none;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
svg {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 0.625rem;
  opacity: 0;
  &-track {
    background: rgba($black, 0);
    border-radius: 0.625rem;
  }
  &-thumb {
    background: rgba($black, 0.2);
    border-radius: 0.625rem;
  }
  &-corner {
    background: transparent;
  }
}
:hover {
  &::-webkit-scrollbar {
    &-track {
      background: rgba($black, 0.1);
    }
    &-thumb {
      background: rgba($black, 0.3);
    }
  }
}
/*--- Custom Gutter ------*/
.gutter {
  &10 {
    margin-inline-start: -0.3125rem;
    margin-inline-end: -0.3125rem;
    & > [class^="col-"],
    & > [class*=" col-"],
    & > .col {
      padding-inline-start: 0.3125rem;
      padding-inline-end: 0.3125rem;
    }
  }
  &16 {
    margin-inline-start: -0.5rem;
    margin-inline-end: -0.5rem;
    & > [class^="col-"],
    & > [class*=" col-"],
    & > .col {
      padding-inline-start: 0.5rem;
      padding-inline-end: 0.5rem;
    }
    .MuiCheckbox-root {
      padding: 0px 10px 0px 0px;
    }
  }
  &20 {
    margin-inline-start: -0.625rem;
    margin-inline-end: -0.625rem;
    & > [class^="col-"],
    & > [class*=" col-"],
    & > .col {
      padding-inline-start: 0.625rem;
      padding-inline-end: 0.625rem;
    }
  }
  &40 {
    margin-inline-start: -1.25rem;
    margin-inline-end: -1.25rem;
    & > [class^="col-"],
    & > [class*=" col-"],
    & > .col {
      padding-inline-start: 1.25rem;
      padding-inline-end: 1.25rem;
    }
  }
  &80 {
    margin-inline-start: -1.25rem;
    margin-inline-end: -1.25rem;
    & > [class^="col-"],
    & > [class*=" col-"],
    & > .col {
      padding-inline-start: 2.5rem;
      padding-inline-end: 2.5rem;
    }
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.top_pannel .title {
  font-size: 18px;
  font-weight: 700;
  color: #0a1d56;
}
.calendar_table {
  padding: 30px 20px;
}
.calendar_ul {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  list-style: none;
  flex-wrap: wrap;
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-inline: -6px;
}
.calendar_ul li {
  padding: 6px;
}
.calendar_wrapper {
  background-color: #f5f5f5;
  border-radius: 10px;
  height: 160px;
}
.month_name {
  color: #626bff;
  text-transform: capitalize;
  line-height: 40px;
  cursor: pointer;
}

$color_1: #0a1d56;
$color_2: rgb(0 0 0 / 50%);
$color_3: #000;

.top_pannel {
  padding: 30px 20px;
  border-block-end: 1px solid #e3e3e3;
  .title {
    font-size: 18px;
    font-weight: 700;
    color: $color_1;
  }
}
.Summary_table {
  ul {
    border: 1px solid #7a7a7a;
    list-style: none;
    padding-inline-start: 0px;
    border-radius: 10px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-block-end: 1px solid #7a7a7a;
      font-size: 15px;
      &:last-child {
        border-block-end: 0px;
      }
      .summary_details {
        width: 80%;
        color: $color_3;
        padding-block: 15px;
        font-weight: 400;
        padding-inline-start: 18px;
      }
      .summary_data {
        width: 20%;
        text-align: center;
        padding-block: 15px;
        border-inline-start: 1px solid #7a7a7a;
        color: $color_3;
        font-weight: 400;
      }
    }
  }
}
.modal-btn-wrapper {
  display: flex;
  justify-content: space-between;
}

.calendar_ul {
  li {
    div {
      p {
        color: #fff;
      }
      padding: 0;
      color: #fff;
      position: relative;
      &.month_name_disabled {
        font-size: 30px;
        font-weight: 700;
        color: #8080809c;
        text-transform: capitalize;
        line-height: 40px;
        cursor: no-drop;
        filter: grayscale(0.9);
        background: #80808070 !important;
        p {
          color: #8080809c;
        }
        &::before {
          opacity: 0.3;
        }
      }
      &::before {
        content: "";
        background-image: url("../src/Assets/calender/date-frame.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      p {
        margin: 0;
        padding: 0.938rem;

        font-size: 30px;
        font-weight: 700;
      }
    }
  }
  li:first-child {
    > div {
      background: linear-gradient(137.2deg, #5cbaff 14.7%, #5a7df9 85.89%);
    }
  }
  li:nth-child(2) {
    > div {
      background: linear-gradient(137.2deg, #ffe259 14.7%, #ffa751 85.89%);
    }
  }
  li:nth-child(3) {
    > div {
      background: linear-gradient(121.07deg, #7eddff 9.3%, #5af9e6 79.21%);
    }
  }
  li:nth-child(4) {
    > div {
      background: linear-gradient(to right, #da4453, #89216b);
    }
  }
  li:nth-child(5) {
    > div {
      background: linear-gradient(107.24deg, #4cbac3 0%, #3ed2af 100%);
    }
  }
  li:nth-child(6) {
    > div {
      background: linear-gradient(137.2deg, #ebdd74 14.7%, #69ebb9 85.89%);
    }
  }
  li:nth-child(7) {
    > div {
      background: linear-gradient(137.2deg, #00e5b1 14.7%, #1cc7b3 85.89%);
    }
  }
  li:nth-child(8) {
    > div {
      background: linear-gradient(102.95deg, #ffa751 -21.49%, #ffe259 107.28%);
    }
  }
  li:nth-child(9) {
    > div {
      background: linear-gradient(to right, #5f2c82, #49a09d);
    }
  }
  li:nth-child(10) {
    > div {
      background: linear-gradient(to right, #159957, #155799);
    }
  }
  li:nth-child(11) {
    > div {
      background: linear-gradient(to right, #44c4b8, #4b84cf);
    }
  }
  li:nth-child(12) {
    > div {
      background: linear-gradient(90deg, #ffafbd 0%, #ffc3a0 100%);
    }
  }
}
.evt-date-show {
  .MuiCardContent-root {
    .MuiPaper-root {
      width: 60px;
      min-width: 60px;
    }
  }
}
.drp-zone-btn {
  width: 100%;
  padding: 10px;
}
